import React, { useEffect, useState } from 'react';
import { GenderAuditWrapper, GenderAuditImageWrapper, GenderAuditButtonWrapper } from './styles';
import { Button } from '@primer/react';
import {BBox, getAuditImages, markAsDeleted, postLabel, postLabelWithValidation} from '../../adapters/label_audit';
import { Text, Stage, Layer, Rect, Image as KonvaImage } from "react-konva";
import Konva from 'konva';


type Annotation = {
  x: number;
  y: number;
  width: number;
  height: number;
  key: string;
  label?: string;
};


export default function LabelAudit() {
  const [imageIndex, setImageIndex] = useState(0);
  const [images, setImages] = useState<{ key: string; imageElement: HTMLImageElement | null }[]>([]);

  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [newAnnotation, setNewAnnotation] = useState<Annotation[]>([]);

  const [noImages, setNoImages] = useState(false);

  const [remaining, setRemaining] = useState(0)
  const [total, setTotal] = useState(0)

  const [isMarkedAsValidation, setIsMarkedAsValidation] = useState(false);

  const handleCheckboxChange = () => {
    setIsMarkedAsValidation(!isMarkedAsValidation);
  };

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (newAnnotation.length === 0) {
      const pointerPosition = event.target.getStage()?.getRelativePointerPosition() || { x: 0, y: 0 };
      const { x, y } = pointerPosition;
      setNewAnnotation([{ x, y, width: 0, height: 0, key: "0" }]);
    }
  };

  const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const pointerPosition = event.target.getStage()?.getRelativePointerPosition() || { x: 0, y: 0 };
      const { x, y } = pointerPosition;
      const annotationToAdd = {
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: (annotations.length + 1).toString()
      };
      annotations.push(annotationToAdd);
      setNewAnnotation([]);
      setAnnotations(annotations);
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const pointerPosition = event.target.getStage()?.getRelativePointerPosition() || { x: 0, y: 0 };
      const { x, y } = pointerPosition;
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: "0",
        }
      ]);
    }
  };

  const annotationsToDraw = [...annotations, ...newAnnotation];

  // Function to scale back an annotation's coordinates to the original image size
  const convertToBBox = (annotation: Annotation): BBox => {
    // Assuming you have these values when rendering the image
    const originalImageWidth = images[imageIndex].imageElement!.width;
    const originalImageHeight = images[imageIndex].imageElement!.height;

    const displayedImageWidth = window.innerWidth;
    const displayedImageHeight = window.innerHeight;

    // Calculate the scaling factors based on how the image was scaled to fit the canvas
    const scaleX = displayedImageWidth / originalImageWidth;
    const scaleY = displayedImageHeight / originalImageHeight;

    const x0 = annotation.x / scaleX;
    const y0 = annotation.y / scaleY;
    const x1 = x0 + annotation.width / scaleX;
    const y1 = y0 + annotation.height / scaleY;

    // Return the BBox
    return [x0, y0, x1, y1, annotation.label];
  };

  const auditImage = async () => {
    const { key: imageKey } = images[imageIndex];
    const bboxes = annotations.map(convertToBBox);
    await postLabelWithValidation(imageKey, bboxes, isMarkedAsValidation);
  };

  const markDeleted = async() => {
    const { key: imageKey } = images[imageIndex];
    await markAsDeleted(imageKey);
    // Check if the current image is the last one
    if (imageIndex >= images.length - 1) {
      await refreshImages();
    } else {
      setImageIndex(imageIndex + 1);
    }
  }

  const undoLastAnnotation = async () => {
    if (annotations.length > 0) {
      console.log("Undoing last annotation");

      // Create a new array without the last annotation
      const updatedAnnotations = annotations.slice(0, -1);

      // Set the updated array in state
      setAnnotations(updatedAnnotations);
    } else {
      console.log("No annotations to undo");
    }
  };

  const refreshImages = async () => {
    const imagesToAudit = await getAuditImages();
    setRemaining(imagesToAudit.data.remaining)
    setTotal(imagesToAudit.data.total)

    // Create an array of promises to load all images
    const loadedImages = await Promise.all(
        imagesToAudit.data.images.map((img: { key: string; base64: string }) => {
          return new Promise<{ key: string; imageElement: HTMLImageElement }>((resolve) => {
            const imgElement = new Image();
            imgElement.src = `data:image/png;base64, ${img.base64}`;
            imgElement.onload = () => {
              resolve({
                key: img.key,
                imageElement: imgElement,
              });
            };
          });
        })
    );
    setImages(loadedImages);
    setImageIndex(0); // Reset the index to start from the first image

    if (loadedImages.length === 0){
      setNoImages(true)
    }
  };

  const submitLabels = async () => {
    const allAnnotationsHaveLabels = annotations.every(annotation => annotation.label !== undefined && annotation.label !== null && annotation.label !== '');

    if (allAnnotationsHaveLabels && annotations.length > 0 && newAnnotation.length === 0) {
      console.log("All annotations have labels set.");
      await auditImage();

      // Reset annotations
      setAnnotations([])
      setNewAnnotation([])

      // Check if the current image is the last one
      if (imageIndex >= images.length - 1) {
        await refreshImages();

      } else {

        setImageIndex(imageIndex + 1);
      }
    } else {
      console.log("Some annotations are missing labels.");
    }
  }

  const handleLabelSelection = async (index: number, gender?: string) => {
    console.log(`Set annotation ${index} as ${gender} for image ${imageIndex}`);

    // Create a new array and update the specific annotation
    const updatedAnnotations = annotations.map((annotation, i) =>
        i === index ? { ...annotation, label: gender } : annotation
    );

    // Set the updated array in state
    setAnnotations(updatedAnnotations);
  };


  useEffect(() => {
    const getImagesToAudit = async () => {
      const imagesToAudit = await getAuditImages();


      setRemaining(imagesToAudit.data.remaining)
      setTotal(imagesToAudit.data.total)

      // Create an array of promises to load all images
      const loadedImages = await Promise.all(
          imagesToAudit.data.images.map((img: { key: string; base64: string }) => {
            return new Promise<{ key: string; imageElement: HTMLImageElement }>((resolve) => {
              const imgElement = new Image();
              imgElement.src = `data:image/png;base64, ${img.base64}`;
              imgElement.onload = () => {
                resolve({
                  key: img.key,
                  imageElement: imgElement,
                });
              };
            });
          })
      );
      setImages(loadedImages);
      if (loadedImages.length === 0){
        setNoImages(true)
      }
    };

    getImagesToAudit();
  }, []);

  return (
      <GenderAuditWrapper>
        <Text as="h1">Gender Audit ({remaining} out of {total})</Text>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px', gap: '10px' }}>
          <Button disabled={imageIndex === 0} onClick={() => setImageIndex(imageIndex - 1)}>Previous</Button>
          <Button disabled={annotations.length === 0} onClick={() => undoLastAnnotation()}>Remove Last Box</Button>
          <Button onClick={() => markDeleted()}>Mark as deleted</Button>
          <Button disabled={imageIndex >= images.length - 1 && newAnnotation.length === 0} onClick={() => setImageIndex(imageIndex + 1)}>Next</Button>
        </div>
        <GenderAuditImageWrapper>
          {
            images?.[imageIndex] ? (
                <Stage
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    width={images[imageIndex].imageElement!.width * Math.min(window.innerWidth / images[imageIndex].imageElement!.width, 1)}
                    height={images[imageIndex].imageElement!.height * Math.min(window.innerHeight / images[imageIndex].imageElement!.height, 1)}
                >
                  <Layer>
                    <KonvaImage
                        image={images[imageIndex].imageElement!}
                        x={0}
                        y={0}
                        width={window.innerWidth}
                        height={window.innerHeight}
                    />

                    {annotationsToDraw.map((value, index) => (
                        <React.Fragment key={index}>
                          <Rect
                              x={value.x}
                              y={value.y}
                              width={value.width}
                              height={value.height}
                              fill="transparent"
                              stroke="lime"
                              strokeWidth={4}
                          />
                          {value.label ? (
                              <Text
                                  x={Math.max(0, Math.min(value.x, images[imageIndex].imageElement!.width - value.width)) + 5}
                                  y={Math.max(0, Math.min(value.y, images[imageIndex].imageElement!.height - value.height)) - 20}
                                  text={`Label ${index} - ${value.label}`}
                                  fontSize={20}
                                  fill="lime"
                                  fontStyle="bold"
                              />
                          ) : (
                              <Text
                                  x={Math.max(0, Math.min(value.x, images[imageIndex].imageElement!.width - value.width)) + 5}
                                  y={Math.max(0, Math.min(value.y, images[imageIndex].imageElement!.height - value.height)) - 20}
                                  text={`Label ${index}`}
                                  fontSize={20}
                                  fill="lime"
                                  fontStyle="bold"
                              />
                          )}
                        </React.Fragment>
                    ))}
                  </Layer>
                </Stage>
            ) : (
                noImages ? (
                    <>No images left</>
                ) : (
                    <>Loading..... Hold tight</>
                )
            )
          }

        </GenderAuditImageWrapper>

        <GenderAuditButtonWrapper>
          <div style={{ marginBottom: '10px' }}>
            <label>
              <input type="checkbox" checked={isMarkedAsValidation} onChange={handleCheckboxChange} />
              Add to Validation dataset
            </label>
          </div>
          <Button disabled={annotations.length === 0} onClick={() => submitLabels()}>Submit labels</Button>
          {annotationsToDraw.map((value, index) => (
              <div>
                {value.label ? (
                    <div>
                      <h1>Selected {value.label} for label {index}</h1>
                      <Button onClick={() => handleLabelSelection(index, undefined)}>Update gender</Button>
                    </div>
                ) : (
                  <div>
                    <h1>Select gender for label {index}</h1>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>

                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Text as="h2">Male</Text>
                        <Button onClick={() => handleLabelSelection(index, '0-20_male')}>[0-20] Male</Button>
                        <Button onClick={() => handleLabelSelection(index, '21-40_male')}>[21-40] Male</Button>
                        <Button onClick={() => handleLabelSelection(index, '41-60_male')}>[41-60] Male</Button>
                        <Button onClick={() => handleLabelSelection(index, '61+_male')}>[61+] Male</Button>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Text as="h2">Female</Text>
                        <Button onClick={() => handleLabelSelection(index, '0-20_female')}>[0-20] Female</Button>
                        <Button onClick={() => handleLabelSelection(index, '21-40_female')}>[21-40] Female</Button>
                        <Button onClick={() => handleLabelSelection(index, '41-60_female')}>[41-60] Female</Button>
                        <Button onClick={() => handleLabelSelection(index, '61+_female')}>[61+] Female</Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          ))}
        </GenderAuditButtonWrapper>
      </GenderAuditWrapper>
  );


}
