import React, {useEffect, useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import {
  DetailsWrapper,
  RowWrapper,
  ToggleContainer,
  ToggleButton,
  ChartWrapper,
  HalfWidthImage,
  ChartImage,
  ImageRowWrapper,
  SectionHeader,
  ContentContainer,
  StyledSidebar,
  ActiveMenuItem
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import weekendAvgDaily from '../../assets/charts/WeekendAverageDailyTraffic.svg';
import weekendAvgHourly from '../../assets/charts/WeekendAverageHourlyTraffic.svg';
import weekdayAvgDaily from '../../assets/charts/WeekdayAverageDailyTraffic.svg';
import weekdayAvgHourly from '../../assets/charts/WeekdayAverageHourlyTraffic.svg';
import age from '../../assets/charts/age.svg';
import gender from '../../assets/charts/gender.svg';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import styled from 'styled-components';
import {
  DailyAverage,
  GetPropertyTrafficResponseType,
  getTrafficByPropertyId, HourlyAverage, getPropertyById, PropertiesDataType, PropertyResponse
} from '../../adapters/properties'
import {UNAUTHORIZED} from "../../consts";

const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

// Utility function to convert time to readable format
const formatTime = (time: string) => {
  const hour = parseInt(time.slice(0, 2), 10);
  const minute = time.slice(2);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12; // Convert to 12-hour format
  return `${formattedHour}:${minute} ${ampm}`;
};

const getMaxValue = (data: HourlyAverage[] | undefined ) => {
  if (data) {
    const max = Math.max(...data.map((item: any) => parseFloat(item.average)));

    return Math.ceil(max / 10) * 10; // Round up to the nearest 10 for a cleaner scale
  }

  return 0
};


function Details() {
  const { property_uuid = '' } = useParams();
  const navigate = useNavigate();
  const [timeToggle, setTimeToggle] = useState('weekday');

  const handleTimeToggle = () => {
    setTimeToggle(prev => (prev === 'weekday' ? 'weekend' : 'weekday'));
  };
  const [view, setView] = useState('hourly'); // State for selected view

  const [dailyAverages, setDailyAverages] = useState<DailyAverage[] | null>(null);
  const [hourlyAverages, setHourlyAverages] = useState<HourlyAverage[] | null>(null)
  const [property, setProperty] = useState<PropertiesDataType | null>(null);

  const dailyBarChartData = dailyAverages?.map((item) => ({
    day: item.day,
    average: parseFloat(item.average),
  })).sort((a, b) => {
    return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
  }) || [];


// Filter, sort, and map the data dynamically based on the toggle
  const filterHourlyData = hourlyAverages?.sort((a, b) => parseInt(a.time) - parseInt(b.time))
      .filter((item) => {
        const hour = parseInt(item.time, 10);
        return (
            item.period === (timeToggle === 'weekday' ? 'Weekday' : 'Weekend') &&
            hour >= 50 // Exclude times between '0000' and '0045'
        );
      }).map((item) => ({
          ...item,
          formattedTime: formatTime(item.time), // Add formatted time field
      }));

  const filterWeekdayData = filterHourlyData?.filter((item) => item.period === 'Weekday')
  const filterWeekendData = filterHourlyData?.filter((item) => item.period === 'Weekend')
  const maxValue = getMaxValue(filterHourlyData)

  useEffect(() => {
    const getProperty = async () => {
      const {data, error}: PropertyResponse = await getPropertyById({uuid: property_uuid});
      if (error) {
        if (error.message === UNAUTHORIZED) {
          navigate('/login');
        }
        return;
      }
      if (data) {
        setProperty(data)
      }

    }

    const getTraffic = async () => {
      const {data, error}: GetPropertyTrafficResponseType = await getTrafficByPropertyId({uuid: property_uuid});
      if (error) {
        if (error.message === UNAUTHORIZED) {
          navigate('/login');
        }
        return;
      }

      if (data) {
        setDailyAverages(data.daily_averages)
        setHourlyAverages(data.hourly_averages)
      }
    };

    getProperty();
    getTraffic();
  }, [navigate, '/']);

  return (
      <ContentContainer>
        <StyledSidebar>
          <Menu>
            {view === 'hourly' ? (
                <ActiveMenuItem onClick={() => setView('hourly')}>Hourly Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('hourly')}>Hourly Averages</MenuItem>
            )}
            {view === 'daily' ? (
                <ActiveMenuItem onClick={() => setView('daily')}>Daily Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('daily')}>Daily Averages</MenuItem>
            )}
            {/*{view === 'age' ? (*/}
            {/*    <ActiveMenuItem onClick={() => setView('age')}>Age Averages</ActiveMenuItem>*/}
            {/*) : (*/}
            {/*    <MenuItem onClick={() => setView('age')}>Age Averages</MenuItem>*/}
            {/*)}*/}
            {/*{view === 'gender' ? (*/}
            {/*    <ActiveMenuItem onClick={() => setView('gender')}>Gender Averages</ActiveMenuItem>*/}
            {/*) : (*/}
            {/*    <MenuItem onClick={() => setView('gender')}>Gender Averages</MenuItem>*/}
            {/*)}*/}
          </Menu>
        </StyledSidebar>

        <DetailsWrapper>
          {view === 'hourly' && (
              <>
                <ToggleContainer>
                  <ToggleButton onClick={handleTimeToggle}>
                    {timeToggle === 'weekday' ? 'Switch to Weekend' : 'Switch to Weekday'}
                  </ToggleButton>
                </ToggleContainer>
                <SectionHeader>
                  {timeToggle === 'weekday' ? 'Average Weekday Traffic for ' :
                      'Average Weekend Traffic for '} {property?.property_name}
                </SectionHeader>

                <RowWrapper>
                  {timeToggle === 'weekday' ? (
                      <>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart
                              data={filterWeekdayData}
                              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="formattedTime" /> {/* Use formatted time */}
                            <YAxis domain={[0, maxValue]} allowDataOverflow={true} />
                            <Tooltip />
                            <Bar dataKey="average" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                  ) : (
                      <>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart
                              data={filterWeekendData}
                              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="formattedTime" /> {/* Use formatted time */}
                            <YAxis domain={[0, maxValue]} allowDataOverflow={true} />
                            <Tooltip />
                            <Bar dataKey="average" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                  )}
                </RowWrapper>
                <ImageRowWrapper>
                  {timeToggle === 'weekday' ? (
                      <>
                        <HalfWidthImage src={weekdayAvgDaily} alt="Weekday Daily Average People" />
                        <HalfWidthImage src={weekdayAvgHourly} alt="Weekday Hourly Average People" />
                      </>
                  ) : (
                      <>
                        <HalfWidthImage src={weekendAvgDaily} alt="Weekend Daily Average People" />
                        <HalfWidthImage src={weekendAvgHourly} alt="Weekend Hourly Average People" />
                      </>
                  )}
                </ImageRowWrapper>
              </>
          )}

          {view === 'daily' && (
              <>
                <SectionHeader>Average Daily Traffic for {property?.property_name}</SectionHeader>
                <RowWrapper>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={dailyBarChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="average" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                </RowWrapper>
              </>
          )}
          {/*{view === 'age' && (*/}
          {/*    <>*/}
          {/*      <SectionHeader>Age Averages for {property?.property_name}</SectionHeader>*/}
          {/*      <RowWrapper>*/}
          {/*        <ChartWrapper>*/}
          {/*          <ChartImage src={age} alt="Age Averages Chart" />*/}
          {/*        </ChartWrapper>*/}
          {/*      </RowWrapper>*/}
          {/*    </>*/}
          {/*)}*/}

          {/*{view === 'gender' && (*/}
          {/*    <>*/}
          {/*      <SectionHeader>Gender Averages for {property?.property_name}</SectionHeader>*/}
          {/*      <RowWrapper>*/}
          {/*        <ChartWrapper>*/}
          {/*          <ChartImage src={gender} alt="Gender Averages Chart" />*/}
          {/*        </ChartWrapper>*/}
          {/*      </RowWrapper>*/}

          {/*    </>*/}
          {/*)}*/}
        </DetailsWrapper>
      </ContentContainer>
  );
}

export default Details;
