import { axiosInstance } from "./instance";
import { UNAUTHORIZED } from "../consts";

export type BBox = [number, number, number, number, string | undefined];

export async function getAuditImages(): Promise<any> {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `images/label`,
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error getting images to audit gender: ${err}`) };
  }
};

export async function markAsDeleted(key: string) {
  const requestBody = {
    is_deleted: true,
    key
  }
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `images/label`,
      data: requestBody
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error posting images to audit ${key}: ${err}`) };
  }
};

export async function postLabel(key: string, bbox: BBox[]) {
  postLabelWithValidation(key, bbox, false)
};

export async function postLabelWithValidation(key: string, bbox: BBox[], is_validation: boolean) {
  const requestBody = {
    bbox,
    key,
    is_validation,
    "action": "update"
  }
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `images/label`,
      data: requestBody
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error posting images to audit ${key}: ${err}`) };
  }
};

export async function deleteLabelAudit(key: string) {
  const requestBody = {
    key,
    "action": "delete"
  }

  try {
    const response = await axiosInstance({
      method: 'post',
      url: `images/label`,
      data: requestBody
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error deleting label ${key}: ${err}`) };
  }
};

