import {axiosInstance} from "./instance";
import {IMAGE_ROUTE, VIDEOS_ROUTE} from "./consts";
import {UNAUTHORIZED} from "../consts";

export type VideoDataType = {
    timestamp_string: string;
    id: string;
    is_processed: boolean;

}

export type VideoType = {
    data: VideoDataType[],
    error: Error;
}

export type VideoImageDataType = {
    key: string;
}

export type VideoImagesType = {
    data: VideoImageDataType[],
    error: Error;
}

// Define the structure for a single bounding box
export type BoundingBox = {
    x1: number; // Left coordinate
    y1: number; // Top coordinate
    x2: number; // Right coordinate
    y2: number; // Bottom coordinate
    label: string; // Label (e.g., "41-60_male")
};


export type VideoImageKeyDataType = {
    bbox: BoundingBox[];
    image: string;
}

export type VideoImageKeyType = {
    data: VideoImageKeyDataType,
    error: Error;
}

export async function getVideosForAudit(): Promise<VideoType | any> {
    try {
        const response = await axiosInstance({
            method: 'get',
            url: `${IMAGE_ROUTE}`,
        })
        return { data: response?.data, error: null};
    } catch (err: any) {
        if (err?.response?.status === 401) {
            return { data: {}, error: new Error(UNAUTHORIZED)}
        }

        return { data: {}, error: new Error(`Error getting videos: ${err}`)};
    }
};

export async function getImagesByVideoId(video_name: string): Promise<VideoImagesType | any> {
    try {
        const response = await axiosInstance({
            method: 'get',
            url: `${IMAGE_ROUTE}${video_name}/`,
        })
        return { data: response?.data, error: null};
    } catch (err: any) {

        if (err?.response?.status === 401) {
            return { data: {}, error: new Error(UNAUTHORIZED)}
        }

        return { data: {}, error: new Error(`Error getting videos from property ${video_name}: ${err}`)};
    }
};

export async function getImagesByKeyAndVideoId(video_name: string, key: string): Promise<VideoImageKeyType | any> {
    try {
        const response = await axiosInstance({
            method: 'get',
            url: `${IMAGE_ROUTE}${video_name}/${key}`,
        })
        return { data: response?.data, error: null};
    } catch (err: any) {

        if (err?.response?.status === 401) {
            return { data: {}, error: new Error(UNAUTHORIZED)}
        }

        return { data: {}, error: new Error(`Error getting images from video ${video_name} and key ${key}: ${err}`)};
    }
};

export async function markVideoAsAudited(video_name: string): Promise<VideoImageKeyType | any> {
    try {
        const response = await axiosInstance({
            method: 'post',
            url: `${VIDEOS_ROUTE}audit`,
            data: {
                "video_name": video_name,
                "is_audited": true,
            }
        })
        return { data: response?.data, error: null};
    } catch (err: any) {

        if (err?.response?.status === 401) {
            return { data: {}, error: new Error(UNAUTHORIZED)}
        }

        return { data: {}, error: new Error(`Error marking video as audited ${video_name}: ${err}`)};
    }
};

