import React, {useRef, useEffect, useState} from 'react';
import {
    Table, Td, Th, VideoWrapper
} from './styles'
import {IconButton} from '@primer/react';
import {RocketIcon} from '@primer/octicons-react'
import {useNavigate, useParams} from 'react-router-dom';

import {UNAUTHORIZED} from '../../../consts';
import {StyledBackText} from '../../../styles';
import {
    getImagesByKeyAndVideoId, BoundingBox,
    getImagesByVideoId, markVideoAsAudited, VideoImagesType,
} from "../../../adapters/videos";
import {postLabelWithValidation, deleteLabelAudit, BBox} from "../../../adapters/label_audit"
import {isEmpty} from "lodash";

export type ImageDataType = {
    image: string | null;
    bbox: BoundingBox[] | null;
    is_validation: boolean;
    key: string | null;
}

type BoundingBoxCanvasProps = {
    imageSrc: string; // The image source (e.g., Base64 string)
    bbox: BoundingBox[]; // Array of bounding box data
};

const BoundingBoxCanvas: React.FC<BoundingBoxCanvasProps> = ({ imageSrc, bbox }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
            // Desired width for the scaled-down image
            const targetWidth = 400; // Adjust this value as needed
            const aspectRatio = img.width / img.height;
            const targetHeight = targetWidth / aspectRatio;

            // Set canvas dimensions to match the scaled-down size
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the scaled-down image
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

            // Set styles for bounding boxes and labels
            ctx.lineWidth = 2;
            ctx.font = '16px Arial';
            ctx.textBaseline = 'top';


            // Scale bounding box coordinates to match the resized image
            if (bbox) {
                bbox.forEach(({x1, y1, x2, y2, label}) => {
                    const scaleX = targetWidth / img.width;
                    const scaleY = targetHeight / img.height;

                    const scaledX1 = x1 * scaleX;
                    const scaledY1 = y1 * scaleY;
                    const scaledX2 = x2 * scaleX;
                    const scaledY2 = y2 * scaleY;

                    // Draw bounding box
                    ctx.strokeStyle = 'red';
                    ctx.strokeRect(scaledX1, scaledY1, scaledX2 - scaledX1, scaledY2 - scaledY1);

                    // Draw label background
                    const textWidth = ctx.measureText(label).width;
                    const textHeight = 16; // Approximate height based on font size
                    ctx.fillStyle = 'rgba(255, 0, 0, 0.7)';
                    ctx.fillRect(scaledX1, scaledY1 - textHeight, textWidth + 4, textHeight);

                    // Draw label text
                    ctx.fillStyle = 'white';
                    ctx.fillText(label, scaledX1 + 2, scaledY1 - textHeight);
                });
            }
        };

        img.onerror = () => {
            console.error('Failed to load image.');
        };
    }, [imageSrc, bbox]);

    return <canvas ref={canvasRef} />;
};

const AuditImageList = () => {
    const [images, setImages] = useState<ImageDataType[] | []>([]);
    const navigate = useNavigate();
    const {video_name = ''} = useParams();

    useEffect(() => {
        const getVideoImages = async () => {
            const {data, error}: VideoImagesType = await getImagesByVideoId(video_name);

            if (error) {
                if (error.message === UNAUTHORIZED) {
                    navigate('/login');
                }
                return;
            }

            if (data) {
                for (const key of data) {
                    try {
                        const imageResponse = await getImagesByKeyAndVideoId(video_name, key.key);

                        const newImage: ImageDataType = {
                            key: key.key,
                            image: imageResponse.data.image,
                            bbox: imageResponse.data.bbox,
                            is_validation: !!(imageResponse.data?.is_validation)
                        };
                        setImages((prevImages) => [...prevImages, newImage]);
                    } catch (err) {
                        console.error(`Failed to fetch image/label for key ${key}`, err);
                        const failedImage: ImageDataType = {key: key.key, image: null, bbox: null, is_validation: false};
                        setImages((prevImages) => [...prevImages, failedImage]);
                    }
                }
            }
        };

        getVideoImages();
    }, [navigate, video_name]);

    const markVideoComplete = async () => {
        markVideoAsAudited(video_name)

    }

    const deleteLabel = (currentImage: ImageDataType) => {
        setImages((images) =>
            images?.map((image) =>
                currentImage.key === image.key ? {...currentImage, bbox: null} : image
            ) || []
        );

        const key = video_name + "/" + currentImage.key;
        deleteLabelAudit(key)
    };

    const updateIsValidation = (currentImage: ImageDataType, isValidation: boolean) => {

        setImages((images) =>
            images?.map((image) =>
                currentImage.key === image.key ? {...currentImage, is_validation: isValidation} : image
            ) || []
        );
        const bbox = currentImage.bbox?.map(({ x1, y1, x2, y2, label }): BBox => [x1, y1, x2, y2, label]) || [];
        console.log(bbox)
        postLabelWithValidation(video_name + "/" + currentImage.key + ".jpg", bbox, isValidation)
    };

    return (
        <VideoWrapper>
            <StyledBackText onClick={() => navigate(-1)}>{'<'} Go Back</StyledBackText>
            <div>Mark as audited <IconButton aria-label="edit" onClick={() => markVideoComplete()} icon={RocketIcon}/>
            </div>
            {
                !isEmpty(images) ? images.map(image => (
                    <Table>
                        <thead>
                        <tr>
                            <Th>Image</Th>
                            <Th>Mark as Validation</Th>
                            <Th>Delete Label</Th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <Td>
                                <BoundingBoxCanvas
                                    imageSrc={`data:image/jpeg;base64,${image.image}`}
                                    bbox={image.bbox!}
                                />
                            </Td>
                            <Td>
                                {image.bbox && image.is_validation && (
                                    <button onClick={() => updateIsValidation(image, false)}>
                                        Unmark as Validation
                                    </button>
                                )}


                                {image.bbox && !image.is_validation && (
                                    <button onClick={() => updateIsValidation(image, true)}>
                                        Mark as Validation
                                    </button>
                                )}

                            </Td>
                            <Td>
                                {image.bbox && (
                                    <button onClick={() => deleteLabel(image)}>
                                        Delete
                                    </button>
                                )}
                            </Td>
                        </tr>
                        </tbody>
                    </Table>
                )) : <>Loading...</>
            }

        </VideoWrapper>
    )
}

export default AuditImageList;
